import axios from 'axios';
import { BaseUrls } from './url';


const httpClient = axios.create({ baseURL: BaseUrls.registerUrl });
httpClient.defaults.headers.common['Accept'] = 'application/json';
httpClient.defaults.headers.common['Content-Type'] = 'application/json';

export const requestRegistration = async (registration) => {
    const url = BaseUrls.registerUrl;
    
    return httpClient.post(url, registration)
        .then(response => response.data)
        .catch(error => error.data);
};