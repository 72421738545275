import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonIcon, IonItem, IonList, IonRow, IonThumbnail } from '@ionic/react';
import { personCircleOutline, shieldCheckmark, bicycle, peopleCircle } from 'ionicons/icons';

import './IntroContainer.css';
interface ContainerProps { }

const IntroContainer: React.FC<ContainerProps> = () => {
  return (
    <IonContent fullscreen>
     <IonThumbnail><img src="assets/images/LogoNew.png" alt='' /></IonThumbnail>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Welcome to myFund</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <p>
            myFund is your Provident Fund tracking app, managed by <br /> <br /><b>The Bargaining Council for the Furniture Manufacturing Industry, KZN</b>.
            <br /><br />As a member of the fund, you can use this app to access:
          </p>
          <IonList>
            <IonItem><IonIcon icon={personCircleOutline} />Your Profile</IonItem>
            <IonItem><IonIcon icon={peopleCircle} />Beneficiaries and Dependents</IonItem>
            <IonItem><IonIcon icon={shieldCheckmark} />Benefit Amounts</IonItem>
            <IonItem><IonIcon icon={bicycle} />Holiday Contributions</IonItem>
          </IonList>
          <p><br />Start using the app by logging in with your <b>Benefit Number</b> and <b>ID Number</b></p>
        </IonCardContent>
      </IonCard>
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonButton expand="block" routerLink='/login'>
              Login
            </IonButton>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonButton expand="block" color={'secondary'} routerLink='/register'>
              Request Registration
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default IntroContainer;
