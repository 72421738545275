import React from 'react';
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonLabel, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { getBenefit } from './../api/BenefitApi';
import { useAuth } from '../hooks/useAuth';
import { shieldCheckmarkOutline } from 'ionicons/icons';

const BenefitPage = () => {
  const [benefit, setBenefit] = React.useState({
    EmployeeId: 0,
    StatementPeriod: '',
    Name: null,
    RegNumber: null,
    RegAddress: null,
    Telephone: null,
    Email: null,
    Fax: null,
    Surname: '',
    FirstName: '',
    IDNumber: '',
    BenefitNo: '',
    Tax: null,
    Employer: '',
    EmployerCode: '',
    RetirementAge: '',
    MonthsToRetirement: '',
    DateJoinedEmployer: '',
    DateJoinedFund: '',
    DOB: '',
    AnnualFund: '',
    OpeningEE: {
      Period: '',
      BalanceDate: '',
      FinancialType: '',
      Amount: 0.00,
      AmountNet: 0.00,
      Units: 0.00
    },
    OpeningER: {
      Period: '',
      BalanceDate: '',
      FinancialType: '',
      Amount: 0.00,
      AmountNet: 0.00,
      Units: 0.00
    },
    ClosingEE: {
      Period: '',
      BalanceDate: '',
      FinancialType: '',
      Amount: 0.00,
      AmountNet: 0.00,
      Units: 0.00
    },
    ClosingER: {
      Period: '',
      BalanceDate: '',
      FinancialType: '',
      Amount: 0.00,
      AmountNet: 0.00,
      Units: 0.00
    },
    VestedBenefitEE: {
      Period: '',
      BalanceDate: '',
      FinancialType: '',
      Amount: 0.00,
      AmountNet: 0.00,
      Units: 0.00
    },
    VestedBenefitER: {
      Period: '',
      BalanceDate: '',
      FinancialType: '',
      Amount: 0.00,
      AmountNet: 0.00,
      Units: 0.00
    },
    FromDate: '',
    ToDate: '',
    FinalMonthPrice: 0.00,
    OpeningMonthPrice: 0.00,
    Transactions: [
      {
        ContributionMonth: '',
        ParticipatingEmployer: '',
        EmployerContribution: null,
        EmployeeContribution: null,
        TotalContribution: null,
        Claims: 0.0,
        Adjustments: 0.0
      }
    ],
    UnitPrice: [
      {
        Name: '',
        UnitPrice: 0.00
      }
    ],
    NormalBenefitValue: 0.00,
    VestednormalBenefitValue: 0.00,
    CurrentPeriod: '',
    TotalOpeningBalance: 0.00,
    TotalClosingBalance: 0.00,
    TotalContribution: 0.00,
    EmployerVested: 0.00,
    EmployeeVested: 0.00,
    TotalVested: 0.00,
    EmployerNonVested: 0.00,
    EmployeeNonVested: 0.00,
    TotalNonVested: 0.00,
    CurrentFundValue: 0.00,
    EmployerTotal: 0.00,
    EmployeeTotal: 0.00,
    NormalRetirementBenefitVested: 0.00,
    NormalRetirementBenefitNonVested: 0.00,
    NormalRetirementBenefit: 0.00,
    UnitsPurchased: 0.00,
    DownloadUrl: ''
  });

  const auth = useAuth();

  function AddTwoValues(employer, employee) {
    let actualER = 0.00;
    let actualEE = 0.00;

    if (employer != null) {
      actualER = employer;
    }
    if (employee != null) {
      actualEE = employee;
    }
    return actualER + actualEE;
  }

  React.useEffect(() => {
    getBenefit(auth.username).then(data => setBenefit(data));
  }, [])
  return (<>
    <IonHeader>
      <IonToolbar>
        <IonTitle>myFund Benefits</IonTitle>
      </IonToolbar>
    </IonHeader>

    <IonContent>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            Period : {benefit?.CurrentPeriod}
          </IonCardTitle>
        </IonCardHeader>
      </IonCard>

      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            Member Details
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonLabel color="medium">
                  Participating Employer
                </IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel color="dark">
                  <b>{benefit?.Employer}</b>
                </IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel color="medium">
                  Date Joined Employer
                </IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel color="dark">
                  <b>{benefit?.DateJoinedEmployer}</b>
                </IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel color="medium">
                  Annual Fund Salary
                </IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel color="dark">
                  <b>{benefit?.AnnualFund}</b>
                </IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel color="medium">
                  Normal Retirement Age
                </IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel color="dark">
                  <b>{benefit?.RetirementAge}</b> years
                </IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel color="medium">
                  Months to Retirement
                </IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel color="dark">
                  <b>{benefit?.MonthsToRetirement}</b> months
                </IonLabel>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardContent>
      </IonCard>

      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            Opening Balance
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonGrid>
            <IonRow>
              <IonCol size='8'>
                <IonLabel color="medium">
                  {benefit?.OpeningER?.FinancialType}
                </IonLabel>
              </IonCol>
              <IonCol size='4'>
                <div className='ion-float-end'>
                  <IonLabel color="dark">
                    R {benefit?.OpeningER?.Amount.toLocaleString()}
                  </IonLabel>
                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size='8'>
                <IonLabel color="medium">
                  {benefit?.OpeningEE?.FinancialType}
                </IonLabel>
              </IonCol>
              <IonCol size='4'>
                <div className='ion-float-end'>
                  <IonLabel color="dark">
                    R {benefit?.OpeningEE?.Amount.toLocaleString()}
                  </IonLabel>
                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size='8'>
                <IonLabel color="dark">
                  <b>
                    Total Contribution
                  </b>
                </IonLabel>
              </IonCol>
              <IonCol size='4'>
                <div className='ion-float-end'>
                  <IonLabel color="dark">
                    <b>R {benefit?.TotalOpeningBalance.toLocaleString()}</b>
                  </IonLabel>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardContent>
      </IonCard>

      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            Contributions
          </IonCardTitle>
        </IonCardHeader>

        <IonGrid>

          {
            benefit?.Transactions?.map(itm => {
              return (
                <IonRow>
                  <IonCol size='1'>
                    <IonIcon icon={shieldCheckmarkOutline} size='large' color='red'></IonIcon>
                  </IonCol>
                  <IonGrid>
                    <IonRow className='cell-class'>
                      <IonCol size='9'>
                        <IonLabel color="dark">
                          <b>
                            {itm.ParticipatingEmployer}
                          </b>
                        </IonLabel>
                      </IonCol>
                      <IonCol>
                        <div className='ion-float-end'>
                          <IonLabel color="dark">
                            <b><u>{itm.ContributionMonth}</u></b>
                          </IonLabel>
                        </div>
                      </IonCol>
                    </IonRow>
                    <IonRow className='cell-class'>
                      <IonCol size='6'>
                        <IonLabel color="medium">
                          Employer
                        </IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <div className='ion-float-end'>
                          <IonLabel color="dark">
                            R {itm.EmployerContribution}
                          </IonLabel>
                        </div>
                      </IonCol>
                    </IonRow>
                    <IonRow className='cell-class'>
                      <IonCol size='6'>
                        <IonLabel color="medium">
                          Employee
                        </IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <div className='ion-float-end'>
                          <IonLabel color="dark">
                            R {itm.EmployeeContribution}
                          </IonLabel>
                        </div>
                      </IonCol>
                    </IonRow>

                    <IonRow className='cell-class'>
                      <IonCol size='6'>
                        <IonLabel color="dark">
                          <b>Total</b>
                        </IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <div className='ion-float-end'>
                          <IonLabel color="dark">
                            <b>{AddTwoValues(itm?.EmployeeContribution, itm.EmployerContribution).toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}</b>
                          </IonLabel>
                        </div>
                      </IonCol>
                    </IonRow>
                  </IonGrid>

                </IonRow>
              )
            })
          }
        </IonGrid>
      </IonCard>

      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            Totals
          </IonCardTitle>
        </IonCardHeader>

        <IonGrid>
          <IonRow>
            <IonCol size='8'>
              <IonLabel color="medium">
                Closing Balance of Contributions
              </IonLabel>
            </IonCol>
            <IonCol>
              <div className='ion-float-end'>
                <IonLabel color="dark">
                  <b>
                    {benefit?.TotalContribution.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}
                  </b>
                </IonLabel>
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='8'>
              <IonLabel color="medium">
                Vested Benefit Fund Value
              </IonLabel>
            </IonCol>
            <IonCol>
              <div className='ion-float-end'>
                <IonLabel color="dark">
                  <b>
                    {benefit?.TotalVested.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}
                  </b>
                </IonLabel>
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size='8'>
              <IonLabel color="medium">
                Non-Vested Benefit Fund Value
              </IonLabel>
            </IonCol>
            <IonCol>
              <div className='ion-float-end'>
                <IonLabel color="dark">
                  <b>
                    {benefit?.TotalNonVested.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}
                  </b>
                </IonLabel>
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size='8'>
              <IonLabel color="medium">
                Current Value inc. Investment Return
              </IonLabel>
            </IonCol>
            <IonCol>
              <div className='ion-float-end'>
                <b>
                  <IonLabel color="dark">
                    {benefit?.CurrentFundValue.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}
                  </IonLabel>
                </b>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>

      </IonCard>

      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            Investment Selection
          </IonCardTitle>
        </IonCardHeader>

        <IonGrid>
          <IonRow>
            <IonCol>
              <IonLabel color="dark">
                <b><u>Account</u></b>
              </IonLabel>
            </IonCol>
            <IonCol>
              <div className='ion-float-end'>
                <IonLabel color="dark">
                  <b><u>Vested</u></b>
                </IonLabel>
              </div>
            </IonCol>
            <IonCol>
              <div className='ion-float-end'>
                <IonLabel color="dark">
                  <b><u>Non-Vested</u></b>
                </IonLabel>
              </div>
            </IonCol>
            <IonCol>
              <div className='ion-float-end'>
                <IonLabel color="dark">
                  <b><u>Value</u></b>
                </IonLabel>
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonLabel color="medium">
                Employer
              </IonLabel>
            </IonCol>
            <IonCol>
              <div className='ion-float-end'>
                <IonLabel color="medium">
                  {benefit.EmployerVested.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}
                </IonLabel>
              </div>
            </IonCol>
            <IonCol>
              <div className='ion-float-end'>
                <IonLabel color="medium">
                  {benefit.EmployerNonVested.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}
                </IonLabel>
              </div>
            </IonCol>
            <IonCol>
              <div className='ion-float-end'>
                <IonLabel color="dark">
                  <b>
                    {benefit.EmployerTotal.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}
                  </b>
                </IonLabel>
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonLabel color="medium">
                Employee
              </IonLabel>
            </IonCol>
            <IonCol>
              <div className='ion-float-end'>
                <IonLabel color="medium">
                  {benefit.EmployeeVested.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}
                </IonLabel>
              </div>
            </IonCol>
            <IonCol>
              <div className='ion-float-end'>
                <IonLabel color="medium">
                  {benefit.EmployeeNonVested.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}
                </IonLabel>
              </div>
            </IonCol>
            <IonCol>
              <div className='ion-float-end'>
                <IonLabel color="dark">
                  <b>
                    {benefit.EmployeeTotal.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}
                  </b>
                </IonLabel>
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonLabel color="dark">
                <b>Total</b>
              </IonLabel>
            </IonCol>
            <IonCol>
              <div className='ion-float-end'>
                <IonLabel color="dark">
                  <b>{benefit.TotalVested.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}</b>
                </IonLabel>
              </div>
            </IonCol>
            <IonCol>
              <div className='ion-float-end'>
                <IonLabel color="dark">
                  <b>{benefit.TotalNonVested.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}</b>
                </IonLabel>
              </div>
            </IonCol>
            <IonCol>
              <div className='ion-float-end'>
                <IonLabel color="dark">
                  <b>{benefit.CurrentFundValue.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}</b>
                </IonLabel>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>

      </IonCard>

      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            Normal Retirement Benefit
          </IonCardTitle>
        </IonCardHeader>

        <IonGrid>
          <IonRow>
            <IonCol>
              <IonLabel color="dark">
                <b><u>Illustrative value of benefit @ Normal Retirement</u></b>
              </IonLabel>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonLabel color="medium">
                Vested Benefit
              </IonLabel>
            </IonCol>
            <IonCol>
              <div className='ion-float-end'>
                <IonLabel color="medium">
                  {benefit.NormalRetirementBenefitVested.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}
                </IonLabel>
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel color="medium">
                Non-Vested Benefit
              </IonLabel>
            </IonCol>
            <IonCol>
              <div className='ion-float-end'>
                <IonLabel color="medium">
                  {benefit.NormalRetirementBenefitNonVested.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}
                </IonLabel>
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel color="dark">
                <b>
                  Total Benefit
                </b>
              </IonLabel>
            </IonCol>
            <IonCol>
              <div className='ion-float-end'>
                <IonLabel color="dark">
                  <b>{benefit.NormalRetirementBenefit.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}</b>
                </IonLabel>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>

      </IonCard>

      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            Benefit If Withdrawal Today
          </IonCardTitle>
        </IonCardHeader>

        <IonGrid>
          <IonRow>
            <IonCol>
              <IonLabel color="dark">
                <b><u>In case of resigning, retrenchment, or dismissal</u></b>
              </IonLabel>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonLabel color="medium">
                Vested Benefit
              </IonLabel>
            </IonCol>
            <IonCol>
              <div className='ion-float-end'>
                <IonLabel color="medium">
                  {benefit.TotalVested.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}
                </IonLabel>
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel color="medium">
                Non-Vested Benefit
              </IonLabel>
            </IonCol>
            <IonCol>
              <div className='ion-float-end'>
                <IonLabel color="medium">
                  {benefit.TotalNonVested.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}
                </IonLabel>
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel color="dark">
                <b>
                  Total Benefit
                </b>
              </IonLabel>
            </IonCol>
            <IonCol>
              <div className='ion-float-end'>
                <IonLabel color="dark">
                  <b>{benefit.CurrentFundValue.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}</b>
                </IonLabel>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>

      </IonCard>

      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            Benefit If Death Occurs Today
          </IonCardTitle>
        </IonCardHeader>

        <IonGrid>
          <IonRow>
            <IonCol>
              <IonLabel color="dark">
                <b><u>In event of death at date of statement</u></b>
              </IonLabel>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonLabel color="medium">
                Vested Benefit
              </IonLabel>
            </IonCol>
            <IonCol>
              <div className='ion-float-end'>
                <IonLabel color="medium">
                  {benefit.TotalVested.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}
                </IonLabel>
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel color="medium">
                Non-Vested Benefit
              </IonLabel>
            </IonCol>
            <IonCol>
              <div className='ion-float-end'>
                <IonLabel color="medium">
                  {benefit.TotalNonVested.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}
                </IonLabel>
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel color="dark">
                <b>
                  Total Benefit
                </b>
              </IonLabel>
            </IonCol>
            <IonCol>
              <div className='ion-float-end'>
                <IonLabel color="dark">
                  <b>{benefit.CurrentFundValue.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}</b>
                </IonLabel>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCard>

      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            <IonButton expand="block" href={benefit?.DownloadUrl} target="_blank" rel="noopener noreferrer">
              Printable Copy
            </IonButton>
          </IonCardTitle>
        </IonCardHeader>
      </IonCard>

      <IonCard>
        <IonCardHeader>
          <IonCardTitle>

          </IonCardTitle>
        </IonCardHeader>
      </IonCard>
    </IonContent >

  </>
  )
}

export default BenefitPage;