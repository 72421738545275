import React from "react";
import { IonTabs, IonTabBar, IonTabButton, IonIcon, IonLabel, IonRouterOutlet, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import BenefitPage from "./BenefitPage";
import HolidayPage from "./HolidayPage";
import ProfilePage from "./ProfilePage";
import RelativesPage from "./RelativesPage";

import { personCircleOutline, shieldCheckmark, bicycle, peopleCircle, planet, giftSharp } from 'ionicons/icons';
import { Redirect, Route } from "react-router";
import { IonReactRouter } from "@ionic/react-router";
import FundPage from "./FundPage";

const Landing: React.FC = () => {
    return (
        <IonReactRouter>
            <IonTabs>
                <IonRouterOutlet>
                    <Redirect exact path="/landing" to="/profile" />
                    <Route path="/profile" render={() => <ProfilePage />} exact={true} />
                    <Route path="/relatives" render={() => <RelativesPage />} exact={true} />
                    <Route path="/benefit" render={() => <BenefitPage />} exact={true} />
                    <Route path="/holiday" render={() => <HolidayPage />} exact={true} />
                    <Route path="/fund" render={() => <FundPage />} exact={true} />
                </IonRouterOutlet>

                <IonTabBar slot="bottom">
                    <IonTabButton tab="profile" href="/profile">
                        <IonIcon icon={personCircleOutline} />
                        <IonLabel>Profile</IonLabel>
                    </IonTabButton>

                    <IonTabButton tab="relatives" href="/relatives">
                        <IonIcon icon={peopleCircle} />
                        <IonLabel>Relatives</IonLabel>
                    </IonTabButton>

                    <IonTabButton tab="benefit" href="/benefit">
                        <IonIcon icon={shieldCheckmark} />
                        <IonLabel>Benefit</IonLabel>
                    </IonTabButton>

                    <IonTabButton tab="holiday" href="/holiday">
                        <IonIcon icon={giftSharp} />
                        <IonLabel>Holiday</IonLabel>
                    </IonTabButton>

                    <IonTabButton tab="fund" href="/fund">
                        <IonIcon icon={planet} />
                        <IonLabel>Fund</IonLabel>
                    </IonTabButton>
                </IonTabBar>
            </IonTabs>
        </IonReactRouter>
    )
}

export default Landing;