import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useState, createContext } from 'react';
import IntroContainer from '../components/IntroContainer';
import './Home.css';
import NavigateContainer from '../components/NavigateContainer';

const ProfileContext = createContext({ authorized: false, benefitNumber: '' });

const Home: React.FC = () => {
  const [profile, setProfile] = useState({ authorized: false, benefitNumber: '' });

  return (
    <ProfileContext.Provider value={profile} >
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>myFund</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          {(profile.authorized === false) && <IntroContainer />}
          {(profile.authorized === true) && <NavigateContainer />}
        </IonContent>
      </IonPage>
    </ProfileContext.Provider>
  );
};

export default Home;
