import { IonButton, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonLabel, IonPage, IonRow, IonThumbnail, IonTitle, IonToast, IonToolbar, useIonLoading } from '@ionic/react';
import { personCircleOutline } from 'ionicons/icons';
import { useState } from 'react';
import { requestRegistration } from '../api/RegisterApi';
import './Home.css';

const Register: React.FC = () => {
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [benefitNo, setBenefitNo] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [mobile, setMobile] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [actionMessage, setActionMessage] = useState("Registration request sent.");
  const [present, dismiss] = useIonLoading();

  const sendRegistration = async () => {
    present({message:"Requesting Registration"});
    const result = await requestRegistration({
      FirstName: firstName,
      Surname: surname,
      BenefitNumber: benefitNo,
      IdentityNumber: idNumber,
      EmailAddress: emailAddress,
      MobileNumber: mobile
    });
    dismiss();
    setActionMessage(result.Message);
    setIsOpen(true);
  };


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Register</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
      <IonThumbnail><img src="assets/images/LogoNew.png" /></IonThumbnail>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonLabel style={{ fontSize: "30px" }}>Request Registration</IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <p>
                Please capture your information for us to register your access to the application.  If you already have your <b>Benefit #</b> and <b>ID/Passport #</b>, you can proceed to login.
              </p>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonInput
                label="First Name"
                labelPlacement="stacked"
                placeholder="Enter your first name"
                clearInput={true}
                onIonChange={(e: any) => setFirstName(e.target.value)}
                fill="outline"></IonInput>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonInput
                label="Surname"
                labelPlacement="stacked"
                placeholder="Enter your surname"
                clearInput={true}
                onIonChange={(e: any) => setSurname(e.target.value)}
                fill="outline"></IonInput>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonInput
                label="Benefit #"
                labelPlacement="stacked"
                placeholder="Enter your benefit #"
                clearInput={true}
                onIonChange={(e: any) => setBenefitNo(e.target.value)}
                fill="outline"
                maxlength={10}></IonInput>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonInput
                label="Identity Number"
                labelPlacement="stacked"
                placeholder="Enter your Id #"
                clearInput={true}
                onIonChange={(e: any) => setIdNumber(e.target.value)}
                fill="outline"
                maxlength={13}></IonInput>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonInput
                label="Email"
                labelPlacement="stacked"
                placeholder="Enter your email address"
                type="email"
                clearInput={true}
                onIonChange={(e: any) => setEmailAddress(e.target.value)}
                fill="outline"></IonInput>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonInput
                label="Mobile"
                labelPlacement="stacked"
                placeholder="Enter your registered phone number"
                type="tel"
                clearInput={true}
                onIonChange={(e: any) => setMobile(e.target.value)}
                fill="outline"></IonInput>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton expand="block" color={'secondary'} onClick={() => sendRegistration()}>
                Request Registration
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonToast
          isOpen={isOpen}
          message={actionMessage}
          onDidDismiss={() => setIsOpen(false)}
          duration={5000}
          position="middle"
        ></IonToast>
      </IonContent>
    </IonPage>
  );
};

export default Register;
