import React from 'react';
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonHeader, IonLabel, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { getBenefit } from '../api/BenefitApi';
import { useAuth } from '../hooks/useAuth';

const FundPage = () => {
  const [benefit, setBenefit] = React.useState({
    EmployeeId: 0,
    StatementPeriod: '',
    Name: null,
    RegNumber: null,
    RegAddress: null,
    Telephone: null,
    Email: null,
    Fax: null,
    Surname: '',
    FirstName: '',
    IDNumber: '',
    BenefitNo: '',
    Tax: null,
    Employer: '',
    EmployerCode: '',
    RetirementAge: '',
    MonthsToRetirement: '',
    DateJoinedEmployer: '',
    DateJoinedFund: '',
    DOB: '',
    AnnualFund: '',
    OpeningEE: {
      Period: '',
      BalanceDate: '',
      FinancialType: '',
      Amount: 0.00,
      AmountNet: 0.00,
      Units: 0.00
    },
    OpeningER: {
      Period: '',
      BalanceDate: '',
      FinancialType: '',
      Amount: 0.00,
      AmountNet: 0.00,
      Units: 0.00
    },
    ClosingEE: {
      Period: '',
      BalanceDate: '',
      FinancialType: '',
      Amount: 0.00,
      AmountNet: 0.00,
      Units: 0.00
    },
    ClosingER: {
      Period: '',
      BalanceDate: '',
      FinancialType: '',
      Amount: 0.00,
      AmountNet: 0.00,
      Units: 0.00
    },
    VestedBenefitEE: {
      Period: '',
      BalanceDate: '',
      FinancialType: '',
      Amount: 0.00,
      AmountNet: 0.00,
      Units: 0.00
    },
    VestedBenefitER: {
      Period: '',
      BalanceDate: '',
      FinancialType: '',
      Amount: 0.00,
      AmountNet: 0.00,
      Units: 0.00
    },
    FromDate: '',
    ToDate: '',
    FinalMonthPrice: 0.00,
    OpeningMonthPrice: 0.00,
    Transactions: [
      {
        ContributionMonth: '',
        ParticipatingEmployer: '',
        EmployerContribution: null,
        EmployeeContribution: null,
        TotalContribution: null,
        Claims: 0.0,
        Adjustments: 0.0
      }
    ],
    UnitPrice: [
      {
        Name: '',
        UnitPrice: 0.00
      }
    ],
    NormalBenefitValue: 0.00,
    VestednormalBenefitValue: 0.00,
    CurrentPeriod: '',
    TotalOpeningBalance: 0.00,
    TotalClosingBalance: 0.00
  });

  const auth = useAuth();

  React.useEffect(() => {
    getBenefit(auth.username).then(data => setBenefit(data));
  }, [])
  return (<>
    <IonHeader>
      <IonToolbar>
        <IonTitle>myFund Manager</IonTitle>
      </IonToolbar>
    </IonHeader>

    <IonContent>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            Fund Details
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonRow>
            <IonCol>
              <IonLabel color="medium">
                Name
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel color="dark">
                <b>Provident Fund For The Furniture Manufacturing Industry, KwaZulu-Natal</b>
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel color="medium">
                Registration #
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel color="dark">
                <b>12/8/37817</b>
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel color="medium">
                Address
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel color="dark">
                <b>36 Dullah Omar Road, Durban, 4001</b>
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel color="medium">
                Telephone
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel color="dark">
                <b>031-301-7788</b>
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel color="medium">
                Fax
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel color="dark">
                <b>031-301-7337</b>
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel color="medium">
                Email
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel color="dark">
                <b>information@bcfmikzn.org</b>
              </IonLabel>
            </IonCol>
          </IonRow>
        </IonCardContent>
      </IonCard>

      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            Administrator Details
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonRow>
            <IonCol>
              <IonLabel color="medium">
                Name
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel color="dark">
                <b>Bargaining Council For The Furniture Manufacturing Industry, KwaZulu-Natal</b>
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel color="medium">
                Registration #
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel color="dark">
                <b>LR/2/6/6/33</b>
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel color="medium">
                Address
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel color="dark">
                <b>36 Dullah Omar Road, Durban, 4001</b>
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel color="medium">
                Telephone
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel color="dark">
                <b>031-301-7788</b>
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel color="medium">
                Fax
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel color="dark">
                <b>031-301-7337</b>
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel color="medium">
                Email
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel color="dark">
                <b>information@bcfmikzn.org</b>
              </IonLabel>
            </IonCol>
          </IonRow>
        </IonCardContent>
      </IonCard>

      <IonCard>
        <IonCardHeader>
          <IonCardTitle>

          </IonCardTitle>
        </IonCardHeader>
      </IonCard>
    </IonContent>
  </>
  )
}

export default FundPage;