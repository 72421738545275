import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import './App.css';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* pages and components */
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import BenefitPage from './pages/BenefitPage';
import ProfilePage from './pages/ProfilePage';
import HolidayPage from './pages/HolidayPage';
import Landing from './pages/Landing';
import RelativesPage from './pages/RelativesPage';
import FundPage from './pages/FundPage';

setupIonicReact();

const App: React.FC = () => (
  <IonReactRouter>
    <IonRouterOutlet>
      
      <IonApp>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/register">
          <Register />
        </Route>
        <Route path="/home">
          <Home />
        </Route>
        <Route path="/benefit">
          <BenefitPage />
        </Route>
        <Route path="/profile">
          <ProfilePage />
        </Route>
        <Route path="/relatives">
          <RelativesPage />
        </Route>
        <Route path="/holiday">
          <HolidayPage />
        </Route>
        <Route path="/landing">
          <Landing />
        </Route>
        <Route path="/fund">
          <FundPage />
        </Route>
        <Route path="/">
          <Redirect to="/home" />
        </Route>
      </IonApp>
    </IonRouterOutlet>
  </IonReactRouter>
);

export default App;
