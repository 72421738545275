import React from 'react';
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonLabel, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { Retrieve } from './../api/ProfileApi';
import { useAuth } from '../hooks/useAuth';
import { useState } from 'react';

const ProfilePage = () => {
  const [profile, setProfile] = useState({
    Id: 0,
    BenefitNumber: '',
    ClockNumber: '',
    Gender: '',
    Race: '',
    Surname: '',
    FirstName: '',
    OtherNames: '',
    IDNumber: '',
    IDType: 0,
    DateOfBirth: '',
    Address1: '',
    Address2: '',
    Address3: '',
    PostalCode: '',
    MailAddress1: '',
    MailAddress2: '',
    MailAddress3: null,
    MailPostalCode: null,
    Telephone: null,
    Mobile: null,
    Email: null,
    Fax: null,
    WorkStatus: '',
    SickFundStatus: '',
    SickFundLastContributionDate: '',
    Doctor: null,
    FinancialStatus: '',
    RegistrationDate: '',
    FirstContributionDate: '',
    LastContributionDate: '',
    Employer: '',
    Occupation: '',
    RateOfPayHourly: null,
    RateOfPayWeekly: 0.00,
    RateOfPayAnnual: 0.00,
    WeeklyHours: null,
    AccountHolder: '',
    Bank: '',
    BranchCode: '',
    AccountNumber: '',
    AccountType: '',
    BankAccountConfirmed: false,
    IsUnionMember: false,
    Union: '',
    UnionNumber: '',
    TaxNumber: null,
    LastEmployedAt: null,
    PreviousEmploymentDate: null,
    DateEngagedFactory: null,
    HousingLoan: false,
    HousingLoanDetail: null,
    Beneficiaries: [{
      Gender: '',
      Surname: '',
      FirstName: '',
      IDNumber: '',
      IDType: 2,
      DateOfBirth: '',
      ContactNumber: '',
      Relationship: ''
    }
    ],
    Dependents: [{
      Gender: '',
      Surname: '',
      FirstName: '',
      IDNumber: '',
      IDType: 2,
      DateOfBirth: '',
      ContactNumber: '',
      Relationship: ''
    }]
  });

  const auth = useAuth();

  function buildName() {
    if (profile.OtherNames != null && profile.OtherNames != '') {
      return profile.Surname.concat(", ").concat(profile.FirstName).concat(" (").concat(profile.OtherNames).concat(")");
    }
    else {
      return profile.Surname.concat(", ").concat(profile.FirstName);
    }
  }

  function buildUnionDetail() {
    if (profile.Union != null && profile.Union != '') {
      let unionName = profile.Union;
      if (profile.UnionNumber != null && profile.UnionNumber != '') {

        return unionName.concat(" (").concat(profile.UnionNumber).concat(")");
      }
      else {
        return unionName.concat(" (UNION NUMBER MISSING)");
      }
    }
    else {
      return 'NOT IN UNION';
    }
  }


  React.useEffect(() => {
    Retrieve(auth.username).then(data => setProfile(data));

  }, [])

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>myFund Profile</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle >
              {buildName()}
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonLabel color="medium">
                    Identity Details
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="dark">
                    <b>{profile.IDNumber} ({profile.IDType == 0 ? "SA ID" : "Passport"})</b>
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="medium">
                    Birth Date
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="dark">
                    <b>{profile.DateOfBirth != null && profile.DateOfBirth.split("T")[0]}</b>
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="medium">
                    Benefit #
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="dark">
                    <b>{profile.BenefitNumber}</b>
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="medium">
                    Demographic (Race/Gender)
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="dark">
                    <b>{profile.Race} / {profile.Gender}</b>
                  </IonLabel>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardContent>

        </IonCard>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>
              Employment
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonLabel color="medium">
                    Employer
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="dark">
                    <b>{profile.Employer}</b>
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="medium">
                    Clock #
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="dark">
                    <b>{profile.ClockNumber}</b>
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="medium">
                    Occupation
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="dark">
                    <b>{profile.Occupation}</b>
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="medium">
                    Registered
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="dark">
                    <b>{profile.RegistrationDate != null ? profile.RegistrationDate.split("T")[0] : ''}</b>
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="medium">
                    First Paid
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="dark">
                    <b>{profile.FirstContributionDate != null && profile.FirstContributionDate.split("T")[0]}</b>
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="medium">
                    Last Paid
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="dark">
                    <b>{profile.LastContributionDate != null && profile.LastContributionDate.split("T")[0]}</b>
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="medium">
                    Financial Status
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="dark">
                    <b>{profile.FinancialStatus}</b>
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="medium">
                    Work Status
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="dark">
                    <b>{profile.WorkStatus}</b>
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="medium">
                    Sick Status
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="dark">
                    <b>{profile.SickFundStatus} ({profile.SickFundLastContributionDate != null && profile.SickFundLastContributionDate.split("T")[0]})</b>
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="medium">
                    Doctor
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="dark">
                    <b>{profile.Doctor}</b>
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="medium">
                    Hourly Pay
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="dark">
                    <b>{profile.RateOfPayHourly}</b>
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="medium">
                    Weekly Pay
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="dark">
                    <b>{profile.RateOfPayWeekly}</b>
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="medium">
                    Annual Pay
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="dark">
                    <b>{profile.RateOfPayAnnual}</b>
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="medium">
                    Weekly Hours
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="dark">
                    <b>{profile.WeeklyHours}</b>
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="medium">
                    Union
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="dark">
                    <b>{buildUnionDetail()}</b>
                  </IonLabel>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardContent>
        </IonCard>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>
              Home Address
            </IonCardTitle>
          </IonCardHeader>
          {
            (profile.Address1 == null && profile.Address2 == null && profile.Address3 == null && profile.PostalCode == null) &&
            <IonCardContent>
              Your physical address is not available.  Please contact the fund manager to update your details.
            </IonCardContent>
          }
          {
            (profile.Address1 != null && profile.Address2 != null && profile.Address3 != null && profile.PostalCode != null) &&
            <IonCardContent>
              <IonLabel color="dark">
                {profile.Address1} <br />
                {profile.Address2} <br />
                {profile.Address3} <br />
                {profile.PostalCode}
              </IonLabel>
            </IonCardContent>
          }
        </IonCard>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>
              Postal Address
            </IonCardTitle>
          </IonCardHeader>
          {
            (profile.MailAddress1 == null && profile.MailAddress2 == null && profile.MailAddress3 == null && profile.MailPostalCode == null) &&
            <IonCardContent>
              Your postal address is not available.  Please contact the fund manager to update your details.
            </IonCardContent>
          }
          {
            (profile.MailAddress1 != null || profile.MailAddress2 != null || profile.MailAddress3 != null || profile.MailPostalCode != null) &&
            <IonCardContent>
              <IonLabel color="dark">
                {profile.MailAddress1} <br />
                {profile.MailAddress2} <br />
                {profile.MailAddress3} <br />
                {profile.MailPostalCode}
              </IonLabel>
            </IonCardContent>
          }
        </IonCard>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>
              Contacts
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonLabel color="medium">
                    Mobile
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="dark">
                    <b>{profile.Mobile}</b>
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="medium">
                    Telephone
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="dark">
                    <b>{profile.Telephone}</b>
                  </IonLabel>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <IonLabel color="medium">
                    Email
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel color="dark">
                    <b>{profile.Email}</b>
                  </IonLabel>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardContent>
        </IonCard>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>
              Financial
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonRow>
              <IonCol>
                <IonLabel color="medium">
                  Bank
                </IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel color="dark">
                  <b>{profile.Bank}</b>
                </IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel color="medium">
                  Branch
                </IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel color="dark">
                  <b>{profile.BranchCode}</b>
                </IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel color="medium">
                  Account #
                </IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel color="dark">
                  <b>{profile.AccountNumber}</b>
                </IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel color="medium">
                  Account Name
                </IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel color="dark">
                  <b>{profile.AccountHolder}</b>
                </IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel color="medium">
                  Tax #
                </IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel color="dark">
                  <b>{profile.TaxNumber}</b>
                </IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel color="medium">
                  House Loan
                </IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel color="dark">
                  <b>{profile.HousingLoan}</b>
                </IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel color="medium">
                  Loan Detail
                </IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel color="dark">
                  <b>{profile.HousingLoanDetail}</b>
                </IonLabel>
              </IonCol>
            </IonRow>
          </IonCardContent>
        </IonCard>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>

            </IonCardTitle>
          </IonCardHeader>
        </IonCard>
      </IonContent>
    </>
  )
};

export default ProfilePage;