import { IonButton, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonLabel, IonPage, IonRow, IonThumbnail, IonTitle, IonToast, IonToolbar, useIonLoading } from '@ionic/react';
import { personCircleOutline } from 'ionicons/icons';
import { Redirect, useHistory } from 'react-router';
import { useAuth, useProvideAuth } from '../hooks/useAuth';
import { useState } from 'react';

import './Home.css';

const Login: React.FC = () => {
  const history = useHistory();
  const auth = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [present, dismiss] = useIonLoading();
  const [actionMessage, setActionMessage] = useState("Registration request sent.");
  const [isOpen, setIsOpen] = useState(false);

  const executeSignIn = async () => {
    await present({ message: "Logging into profile" });
    const result = await auth.signIn(username, password);
    await dismiss();
    if (result.success) {
      history.push('/landing');
    } else {
      setActionMessage("Login failed with message '".concat(result.message).concat("'"));
      await dismiss();
      setIsOpen(true);
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Sign In</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}>
        <IonThumbnail><img src="assets/images/LogoNew.png" /></IonThumbnail>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonLabel style={{ fontSize: "30px" }}>Sign In</IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonInput
                label="Benefit #"
                labelPlacement="stacked"
                placeholder="Enter your benefit #"
                clearInput={true}
                //onIonChange={(e: any) => setUsername(e.target.value)}
                onIonInput={(e: any) => setUsername(e.target.value)}
                fill="outline"
                maxlength={10}></IonInput>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonInput
                label="Last 6 digits of Id"
                labelPlacement="stacked"
                placeholder="Enter the last six digits of your Id #"
                type="password"
                clearInput={true}
                //onIonChange={(e: any) => setPassword(e.target.value)}
                onIonInput={(e: any) => setPassword(e.target.value)}
                fill="outline"
                maxlength={6}></IonInput>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton expand="block" onClick={() => executeSignIn()}>
                Login
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton expand="block" color={'secondary'} routerLink='/register'>
                Request Registration
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonToast
          isOpen={isOpen}
          message={actionMessage}
          onDidDismiss={() => setIsOpen(false)}
          duration={5000}
          position="middle"
        ></IonToast>

      </IonContent>
    </IonPage>
  );
};

export default Login;
