import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonGrid, IonIcon, IonRow } from '@ionic/react';
import { medalOutline } from 'ionicons/icons';
import './NavigateContainer.css';

interface ContainerProps { }

const NavigateContainer: React.FC<ContainerProps> = () => {
  return (
    <IonContent fullscreen>
      <IonIcon icon={medalOutline} style={{ fontSize: "120px", justifyContent: "center", display: "flex", alignItems: "center" }} color="primary"></IonIcon>
      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle>You are in</IonCardSubtitle>
          <IonCardTitle>Do an action here</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <p>
            myFund is your Provident Fund tracking app, managed by <br /> <br /><b>The Bargaining Council for the Furniture Manufacturing Industry, KZN</b>.
            <br /><br />As a member of the fund, you can use this app to:
          </p>
          <p>Start using the app by logging in with your <b>Benefit Number</b> and <b>ID Number</b></p>
        </IonCardContent>
      </IonCard>
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonButton expand="block" routerLink='/login'>
              Login
            </IonButton>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonButton expand="block" color={'secondary'} routerLink='/register'>
              Request Registration
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default NavigateContainer;
