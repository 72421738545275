import React, { useContext } from 'react';
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonLabel, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { Retrieve } from '../api/ProfileApi';
import { useAuth } from '../hooks/useAuth';
import { removeCircleOutline, bicycleSharp, addCircle, shieldCheckmark, personAdd, people } from 'ionicons/icons';

const RelativesPage = () => {
  const [profile, setProfile] = React.useState({
    Id: 0,
    BenefitNumber: '',
    ClockNumber: '',
    Gender: '',
    Race: '',
    Surname: '',
    FirstName: '',
    OtherNames: '',
    IDNumber: '',
    IDType: 0,
    DateOfBirth: '',
    Address1: '',
    Address2: '',
    Address3: '',
    PostalCode: '',
    MailAddress1: '',
    MailAddress2: '',
    MailAddress3: null,
    MailPostalCode: null,
    Telephone: null,
    Mobile: null,
    Email: null,
    Fax: null,
    WorkStatus: '',
    SickFundStatus: '',
    SickFundLastContributionDate: '',
    Doctor: null,
    FinancialStatus: '',
    RegistrationDate: '',
    FirstContributionDate: '',
    LastContributionDate: '',
    Employer: '',
    Occupation: '',
    RateOfPayHourly: null,
    RateOfPayWeekly: 0.00,
    RateOfPayAnnual: 0.00,
    WeeklyHours: null,
    AccountHolder: '',
    Bank: '',
    BranchCode: '',
    AccountNumber: '',
    AccountType: '',
    BankAccountConfirmed: false,
    IsUnionMember: false,
    Union: null,
    UnionNumber: null,
    TaxNumber: null,
    LastEmployedAt: null,
    PreviousEmploymentDate: null,
    DateEngagedFactory: null,
    HousingLoan: false,
    HousingLoanDetail: null,
    Beneficiaries: [{
      Gender: '',
      Surname: '',
      FirstName: '',
      IDNumber: '',
      IDType: 2,
      DateOfBirth: '',
      ContactNumber: '',
      Relationship: ''
    }
    ],
    Dependents: [{
      Gender: '',
      Surname: '',
      FirstName: '',
      IDNumber: '',
      IDType: 2,
      DateOfBirth: '',
      ContactNumber: '',
      Relationship: ''
    }]
  });

  const auth = useAuth();

  React.useEffect(() => {
    Retrieve(auth.username).then(data => setProfile(data));
  }, [])

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>myFund Relatives</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {(profile == null || profile?.Beneficiaries == null || profile?.Beneficiaries.length == 0) &&
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>No Beneficiaries</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              You have no Beneficiaries against your profile.  You may register beneficiaries by contacting the fund administrators.
            </IonCardContent>
          </IonCard>}
        {(profile?.Beneficiaries.length > 0) &&
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>
                Beneficiaries
              </IonCardTitle>
            </IonCardHeader>

            <IonGrid>
              {
                profile?.Beneficiaries?.map(itm => {
                  return (
                    <IonRow>
                      <IonCol size='1'>
                        <IonIcon icon={people} size='large' color="red"></IonIcon>
                      </IonCol>
                      <IonGrid>
                        <IonRow>
                          <IonCol size='11'>
                            <IonLabel color="dark">
                              <b>{itm.Surname} {itm.FirstName}</b>
                            </IonLabel>
                          </IonCol>
                        </IonRow>
                        <IonRow className='cell-class'>
                          <IonCol size='4'>
                            <IonLabel color="dark">
                              {(itm.DateOfBirth != null) && new Date(itm.DateOfBirth).toLocaleDateString()}
                            </IonLabel>
                          </IonCol>
                          <IonCol size='4'>
                            <IonLabel color="dark">
                              {itm.Gender}
                            </IonLabel>
                          </IonCol>
                          <IonCol size='4'>
                            <div className='ion-float-end'>
                              <IonLabel color="dark">
                                <b>{itm.Relationship}</b>
                              </IonLabel>
                            </div>
                          </IonCol>
                        </IonRow>
                      </IonGrid>

                    </IonRow>
                  )
                })
              }
            </IonGrid>
          </IonCard>
        }

        {(profile == null || profile?.Dependents == null || profile?.Dependents.length == 0) &&
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>No Dependents</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              You have no <b>Dependents</b> against your profile.  You may register dependents by contacting the fund administrators.
            </IonCardContent>
          </IonCard>}
        {(profile?.Beneficiaries.length > 0) &&
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>
                Dependents
              </IonCardTitle>
            </IonCardHeader>

            <IonGrid>
              {
                profile?.Dependents?.map(itm => {
                  return (
                    <IonRow>
                      <IonCol size='1'>
                        <IonIcon icon={personAdd} size='large' color="red"></IonIcon>
                      </IonCol>
                      <IonGrid>
                        <IonRow>
                          <IonCol size='11'>
                            <IonLabel color="dark">
                              <b>
                                {itm.Surname} {itm.FirstName}
                              </b>
                            </IonLabel>
                          </IonCol>
                        </IonRow>
                        <IonRow className='cell-class'>
                          <IonCol size='4'>
                            <IonLabel color="dark">
                              {(itm.DateOfBirth != null) && new Date(itm.DateOfBirth).toLocaleDateString()}
                            </IonLabel>
                          </IonCol>
                          <IonCol size='4'>
                            <IonLabel color="dark">
                              {itm.Gender}
                            </IonLabel>
                          </IonCol>
                          <IonCol size='4'>
                            <div className='ion-float-end'>
                              <IonLabel color="dark">
                                <b>{itm.Relationship}</b>
                              </IonLabel>
                            </div>
                          </IonCol>
                        </IonRow>
                      </IonGrid>

                    </IonRow>
                  )
                })
              }
            </IonGrid>
          </IonCard>
        }
      </IonContent>
    </>
  )
};

export default RelativesPage;