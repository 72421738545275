import axios from 'axios';
import { BaseUrls } from './url';


const httpClient = axios.create({ baseURL: BaseUrls.profileUrl });
httpClient.defaults.headers.common['Accept'] = 'application/json';
httpClient.defaults.headers.common['Content-Type'] = 'application/json';
//httpClient.defaults.headers.common['Authorization'] = USER_TOKEN;

export const Retrieve = async (id) => {
    const url = BaseUrls.profileUrl.concat("?id=").concat(id);
    return httpClient.get(url)
        .then(response => response.data)
        .catch(error => error.data);
};