import React from 'react';
import { createRoot } from 'react-dom/client';
import { AuthProvider } from './components/AuthProvider';
import App from './App';
import { ProvideAuth } from './hooks/useAuth';

const root = createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <ProvideAuth>
        <App />
      </ProvideAuth>
    </AuthProvider>
  </React.StrictMode>
);
