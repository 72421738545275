import React from 'react';
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonLabel, IonRow, IonTitle, IonToolbar, useIonLoading } from '@ionic/react';
import { getHoliday } from './../api/HolidayApi';
import { useAuth } from '../hooks/useAuth';
import { removeCircleOutline, bicycleSharp, giftSharp } from 'ionicons/icons';
import { useState } from 'react';

const HolidayPage = () => {
  const [holiday, setHoliday] = React.useState({
    EmployeeId: 0,
    BenefitNumber: '',
    IDNumber: '',
    FirstName: '',
    Surname: '',
    EmployerCode: '',
    EmployerName: '',
    Mobile: '',
    Email: '',
    Address: '',
    Occupation: '',
    ClockNumber: '',
    RateOfPayWeekly: 0.00,
    AccountNumber: '',
    BankName: '',
    BranchCode: '',
    PaymentAmount: 0.00,
    LeavePayAmount: 0.00,
    BonusPayAmount: 0.00,
    BalanceBF: 0.00,
    PeriodName: '',
    WorkStatus: '',
    DownloadUrl: '',
    Details: [
      {
        Period: '',
        BatchNo: '',
        LineNumber: 0,
        Amount: 0.00,
        EmployerCode: '',
        EmployerName: '',
        SourceType: 1
      }
    ]
  });

  const auth = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [actionMessage, setActionMessage] = useState("Registration request sent.");
  const [present, dismiss] = useIonLoading();

  React.useEffect(() => {
    getHoliday(auth.username).then(data => setHoliday(data));
  }, [])

  function BankDetails() {
    if (holiday == null || (holiday.BankName == null && holiday.BranchCode == null && holiday.AccountNumber == null)) {
      return <IonCardContent>
        No banking details could be found.  If you are sure that you should have bank details, contact the fund manager.
      </IonCardContent>
    }
    return <IonCardContent>
      <IonRow>
        <IonCol>
          <IonLabel color="medium">
            Bank
          </IonLabel>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonLabel color="dark">
            <b>{holiday?.BankName}</b>
          </IonLabel>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonLabel color="medium">
            Branch Code
          </IonLabel>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonLabel color="dark">
            <b>{holiday?.BranchCode}</b>
          </IonLabel>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonLabel color="medium">
            Account #
          </IonLabel>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonLabel color="dark">
            <b>{holiday?.AccountNumber}</b>
          </IonLabel>
        </IonCol>
      </IonRow>
    </IonCardContent>
  }

  return (<>
    <IonHeader>
      <IonToolbar>
        <IonTitle>myFund Holiday</IonTitle>
      </IonToolbar>
    </IonHeader>
    {
      (holiday != null) &&
      <IonContent>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>
              Bank Account To Pay
            </IonCardTitle>
          </IonCardHeader>
          <BankDetails />
        </IonCard>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>
              Balance
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonGrid>
              <IonRow>
                <IonCol size='6'>
                  <IonLabel color="medium">
                    <b>Opening Balance</b>
                  </IonLabel>
                </IonCol>
                <IonCol size='6'>
                  <div className='ion-float-end'>
                    <IonLabel color="dark">
                      <b>
                        {holiday.BalanceBF.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}
                      </b>
                    </IonLabel>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardContent>
        </IonCard>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>
              Contributions
            </IonCardTitle>
          </IonCardHeader>
          <IonGrid>

            {
              holiday?.Details?.map(itm => {
                return (
                  <IonRow>
                    <IonCol size='1'>
                      {
                        (itm.SourceType == 1) && <IonIcon icon={giftSharp} size='large' color="red"></IonIcon>
                      }
                      {
                        (itm.SourceType == 4) && <IonIcon icon={removeCircleOutline} size='large' color="red"></IonIcon>
                      }
                    </IonCol>
                    <IonGrid>
                      <IonRow>
                        <IonCol size='11'>
                          <IonLabel color="dark">
                            <b>
                              {itm.EmployerName}
                            </b>
                          </IonLabel>
                        </IonCol>
                      </IonRow>
                      <IonRow className='cell-class'>
                        <IonCol size='4'>
                          <IonLabel color="dark">
                            {itm.BatchNo}/{itm.LineNumber}
                          </IonLabel>
                        </IonCol>
                        <IonCol size='4'>
                          <IonLabel color="dark">
                            {itm.Period}
                          </IonLabel>
                        </IonCol>
                        <IonCol size='4'>
                          <div className='ion-float-end'>
                            <IonLabel color="dark">
                              <b>{itm.Amount.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}</b>
                            </IonLabel>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>

                  </IonRow>
                )
              })
            }
          </IonGrid>
        </IonCard>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>
              To Pay
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonGrid>
              <IonRow>
                <IonCol size='6'>
                  <IonLabel color="medium">
                    <b>Holiday Payout</b>
                  </IonLabel>
                </IonCol>
                <IonCol size='6'>
                  <div className='ion-float-end'>
                    <IonLabel color="dark">
                      <b>
                      {holiday?.PaymentAmount.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}
                      </b>
                    </IonLabel>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardContent>
        </IonCard>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>
              <IonButton expand="block" href={holiday?.DownloadUrl} target="_blank" rel="noopener noreferrer">
                Printable Copy
              </IonButton>
            </IonCardTitle>
          </IonCardHeader>
        </IonCard>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>

            </IonCardTitle>
          </IonCardHeader>
        </IonCard>
      </IonContent>
    }
  </>)
}

export default HolidayPage;